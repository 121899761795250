.wrapper {
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .card {
    color: #fff;
    max-width: 1200px;
    width: 100%;
    height: 680px;
    margin: 30px auto 0px auto;
    border-radius: 32px;
    background: rgb(29, 3, 54);
    background: -moz-linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );
    background: -webkit-linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );
    background: linear-gradient(
      129deg,
      rgba(29, 3, 54, 1) 0%,
      rgba(84, 10, 156, 1) 84%
    );

    .header {
      margin: 20px;
      display: flex;
      justify-content: flex-start;

      svg {
        cursor: pointer;
      }
    }

    .content {
      width: 85%;
      margin: 0 auto;

      h3 {
        font-family: "Montserrat-SemiBold";
        font-size: 20px;
      }

      span {
        font-size: 16px;
      }

      button {
        background-color: #fff !important;
        color: #000 !important;
        margin: 50px auto !important;
      }
    }

    .desktopCard {
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      text-align: left;
      width: 80%;
      margin: 0 auto;
      div {
        &:first-child {
          width: 50%;

          div {
            width: 80%;
            text-align: left;
            margin: 20px auto;
          }
        }

        &:last-child {
          width: 50%;
          text-align: center;
        }
      }
    }
    .mainButton {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        background-color: #fff !important;
        color: #000 !important;
        margin: 50px auto !important;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;

    .card {
      width: 90%;
      height: 780px;
      margin: 30px auto;
    }
  }
}
