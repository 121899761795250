.container {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;

  img {
    margin-top: 80px;
  }

  h2 {
    font-size: 24px;
    font-family: "Montserrat-SemiBold";
  }

  span {
    color: #979797;
    font-family: "Montserrat-Medium";
    font-size: 14px;
  }

  button {
    margin-top: 50px;
  }
}
