.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  min-height: 500px;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 20px;

  .successIcon {
    margin: 30px 0px 10px 0px;
  }

  .infoBox {
    width: 80%;
    height: 142px;
    border: 2px solid #1a7fdc;
    border-radius: 12px;
    margin: 20px auto;
    background-color: #dbf6ff;
    padding: 10px;
    display: flex;
    text-align: left;
    align-items: baseline;
    position: relative;

    h3 {
      margin-top: 0px;
    }

    div {
      height: 100%;

      &:first-child {
        position: absolute;
        top: 10px;
        left: 20px;
      }

      &:last-child {
        margin-left: 40px;
      }
    }
  }

  button {
    margin-top: 40px;
  }

  @media screen and (max-width: 1450px) {
    max-width: 350px;
  }

  @media screen and (max-width: 1350px) {
    max-width: 330px;
  }

  @media screen and (max-width: 1280px) {
    max-width: 260px;
  }

  @media screen and (max-width: 1180px) {
    max-width: 300px !important;
  }
}
