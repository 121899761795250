.cardContainer {
  width: 90%;
  height: 305px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .cardImage {
    height: 100%;
    width: 100%;
    max-width: 380px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .content {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 45%;
    margin: 0 auto;
  }

  .title {
    font-size: 20px;
    font-family: "Montserrat-Bold";
    margin: 0 0 12px 0;
  }

  .subtitle {
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    margin: 0 0 12px 0;
  }

  .description {
    font-size: 16px;
    margin: 0 0 16px 0;
  }

  .mainButton {
    display: flex;
    justify-content: center;
    width: 205px;
    button {
      width: 100%;
      padding: 0px 15px;
      height: 40px;
      font-size: 16px;
      font-family: "Montserrat-SemiBold";
      color: #fff;
      background-color: var(--color);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;
      border: none;
      &:hover {
        // background-color: #f0f0f0;
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    height: 405px;

    .content {
      width: 90%;
    }
    .cardImage {
      max-width: unset;
      width: 100%;
      height: 155px;
    }
    .mainButton {
      margin: 0 auto;
    }
  }
}
