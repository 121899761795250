.wrapper {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-top: 10px;

    .backButtonContainer {
      display: flex;
      align-items: center;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    color: #333;
  }

  .content {
    width: 400px;
    min-height: 505px;
    margin: 50px auto 20px auto;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px 0;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      height: 90px;
      margin: 6px auto;
      padding: 0 15px;
      border: 1px solid #b7bdc0;
      border-radius: 12px;
      position: relative;

      .category {
        font-size: 14px;
        font-family: "Montserrat-SemiBold";
        color: #333;
        position: absolute;
        top: 5px;
        left: 10px;
        z-index: 3;
      }

      .bodyValue,
      .aromaValue,
      .flavorValue,
      .acidityValue,
      .sweetnessValue {
        span {
          margin-left: 10px;
        }
      }

      .bodySelected,
      .aromaSelected,
      .flavorSelected,
      .aciditySelected,
      .sweetnessSelected {
        word-break: break-word;
      }

      .bodyValue {
        position: absolute;
        left: 50%;
        display: flex;
        align-items: center;
      }

      .bodyLine {
        position: absolute;
        bottom: -49px;
        left: 40%;
        z-index: 1;
      }

      .bodySelected {
        position: absolute;
        left: 70%;
      }

      .aromaValue {
        position: absolute;
        left: 31%;
        display: flex;
        align-items: center;
      }

      .aromaLine {
        position: absolute;
        bottom: -43px;
        left: 39.5%;
        z-index: 1;
      }

      .aromaSelected {
        position: absolute;
        left: 51%;
      }

      .flavorValue {
        position: absolute;
        left: 70.5%;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        span {
          margin-right: 10px;
        }
      }

      .flavorLine {
        position: absolute;
        bottom: -43px;
        left: 59.5%;
        z-index: 1;
      }

      .flavorSelected {
        position: absolute;
      }

      .acidityValue {
        position: absolute;
        left: 50.5%;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        span {
          margin-right: 10px;
        }
      }

      .acidityLine {
        position: absolute;
        bottom: -43px;
        left: 21.5%;
        z-index: 1;
      }

      .aciditySelected {
        position: absolute;
        left: 30.5%;
      }

      .sweetnessValue {
        position: absolute;
        left: 12%;
        display: flex;
        align-items: center;
      }

      .sweetnessSelected {
        position: absolute;
        left: 32%;
      }

      .dotBox {
        width: 58px;
        height: 28px;
        background-color: var(--color, #b7bdc0);
        border-radius: 2px;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 10px;
          height: 10px;
          circle {
            fill: #fff;
          }
        }
      }

      .label {
        font-size: 14px;
        color: #555;
        text-align: center;
        flex: 1;
        margin-left: 10px;
      }
    }

    .bottomActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 40px 0px;

      div {
        margin-top: 20px;
        cursor: pointer;
        span {
          font-family: "Montserrat-SemiBold";
          font-size: 18px;
          color: var(--color);
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
    .content {
      width: 90%;
    }
  }
}
