.medalsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  justify-content: center;
  margin: 0 auto;
  text-align: center;

  .inputs {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    input {
      background-color: #fff;
      height: 60px;
      border: none;
      border-radius: 4px;
      width: 100%;
      font-size: 16px;
      outline: none;
      margin: 5px 0px;
      font-family: "Montserrat-Medium";
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);

      &::placeholder {
        color: #000;
      }
    }

    .maxAmountButton {
      position: absolute;
      right: 10px;
      top: 25px;
      font-size: 12px;
      cursor: pointer;
      font-family: "Montserrat-SemiBold";
      color: var(--color);
    }

    .selectBox {
      position: relative;
      transition: 0.1s ease-in-out;

      .activeLabel {
        position: absolute;
        font-size: 12px;
        top: 18px;
        left: 15px;
      }

      .nullValueLabel {
        position: absolute;
        font-size: 16px;
        top: 33px;
        left: 15px;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .calculations {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    .balance {
      color: var(--color);
      font-size: 16px;
      width: 45%;
    }
  }

  .selectBox {
    position: relative;
    transition: 0.1s ease-in-out;
    width: 100%;
    margin-bottom: 20px;

    .activeLabel {
      position: absolute;
      font-size: 12px;
      top: 18px;
      left: 15px;
    }
  }
  .selectInput {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    height: 64px;
    border: none;
    border-radius: 4px;
    padding: 20px 15px 5px 15px;
    font-size: 16px;
    outline: none;
    margin: 10px 0px 5px 0px;
    font-family: "Montserrat-Regular";
    box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);
    color: #000;

    &::placeholder {
      color: var(--color);
    }

    &:disabled {
      opacity: 1;
    }
  }

  .convertingToLabel {
    color: var(--color);
    margin: 40px 0px;
  }

  @media screen and (max-width: 1450px) {
    max-width: 350px;
  }

  @media screen and (max-width: 1350px) {
    max-width: 330px;
  }

  @media screen and (max-width: 1280px) {
    max-width: 260px;
  }

  @media screen and (max-width: 1180px) {
    max-width: 300px !important;
  }
}
