.mainWrapper {
  width: 90%;
  height: 64dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px 0px;

    h2 {
      font-family: "Montserrat-Medium";
      font-size: 24px;
    }

    span {
      font-family: "Montserrat-Medium";
      font-size: 16px;
      color: #a5a5a5;
    }
  }

  .twoFactorInput {
    width: 320px;
    height: 56px;
    background-color: #f5f5f5;
    border: 1px solid var(--color);
    border-radius: 8px;
    margin: 20px auto 0px auto;

    input {
      width: 92%;
      height: 100%;
      background-color: transparent;
      border-color: transparent;
      outline: none;
      font-size: 20px;
      padding: 0px 10px;
      text-align: center;

      &::placeholder {
        color: var(--color);
        text-align: center;
      }
    }
  }

  .button {
    margin: 50px auto 0px auto;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  @media screen and (max-width: 1180px) {
    height: 80dvh;
  }
}
