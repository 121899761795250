.cardContainer {
  width: 90%;
  height: 455px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px 0px;

  .question {
    width: 90%;
    font-size: 18px;
    font-weight: bold;
    margin: 0px auto 20px auto;
  }

  .optionsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0px auto 20px auto;
  }
  .option {
    background: #eaeaea;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    transition: background 0.1s ease;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  .selected {
    background: black;
    color: white;
  }

  button {
    margin: 0 auto;
  }

  @media screen and (max-width: 1180px) {
    height: 405px;
  }
}
