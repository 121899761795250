.momentsWrapper {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    .backButtonContainer {
      display: flex;
      align-items: center;
    }

    .rightLabel {
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
      letter-spacing: -0.28px;
    }
  }

  .step {
    padding: 20px;

    h2 {
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
      color: var(--color);
      max-width: 50%;
      margin: 0px auto 20px auto;
    }
  }

  .answersCard {
    background-color: #fff;
    min-height: 300px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .options {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
    }
    .nextButton {
      margin: 20px auto;
    }
  }

  .optionButton {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: #000;
    border-radius: 12px;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 1px solid #000;
    font-size: 16px;

    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .selected {
    background-color: #000;
    color: white;
  }

  .submitAnswersContent {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;

    div {
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      svg {
        margin-top: 20px;
      }
    }
    h2 {
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
      color: var(--color);
    }

    button {
      margin: 100px auto 0px auto;
    }

    .bottomActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 40px 0px;

      div {
        margin-top: 20px;
        cursor: pointer;
        span {
          font-family: "Montserrat-SemiBold";
          font-size: 18px;
          color: var(--color);
        }
      }
      button {
        margin: 0px auto;
      }

      .redoButton {
        min-height: unset;
      }
    }
  }

  .signupHints {
    min-height: unset !important;
    justify-content: unset !important;
    margin: 20px 0px;
    font-size: 14px;

    div {
      min-height: unset !important;
      display: inline !important;
      justify-content: flex-start !important;
      margin: 30px 0px;
      color: #ff6333;

      span {
        &:first-child {
          text-transform: uppercase;
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
    .step {
      h2 {
        max-width: 90%;
      }
    }
    .answersCard {
      min-height: 300px;
    }

    .optionButton {
      text-align: left;
    }
  }
}
