.cardContainer {
  width: 90%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0px;
  background-color: #fff;

  .chatTimeline {
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .question {
      width: 100%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border-radius: 12px;
      border: 1px solid var(--color);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 30px;
      text-align: center;

      span {
        padding: 0px 20px;
        &:first-child {
          font-size: 16px;
        }
      }
    }

    .answer {
      width: 80%;
      min-height: 95px;
      background-color: #f2e6ff80;
      border: 1px solid var(--color);
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 40px;
      padding: 20px;
      font-size: 15px;

      .answerTouchLine {
        position: absolute;
        left: -21px;
        top: -43px;
      }

      span {
        margin-bottom: 20px;
      }

      .colored {
        color: #ff6333;
        font-family: "Montserrat-Medium";
        margin-bottom: 5px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    width: 240px;

    .openChatAction {
      cursor: pointer;
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1180px) {
    .chatTimeline {
      width: 90%;
      margin-bottom: 20px;
    }
  }
}
