.cardContainer {
  width: 90%;
  height: 470px;
  background-image: linear-gradient(
    to right bottom,
    #540a9c,
    #450981,
    #370867,
    #29074e,
    #1d0336
  );
  border-radius: 16px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin: 10px 0px;
  overflow: hidden;
}

.thankYouMessage {
  font-size: 20px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 16px;
}

.question {
  font-size: 20px;
  margin-bottom: 16px;
}

.pollResultsCard {
  width: 90%;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #fff;
  margin: 30px auto 0px auto;
  gap: 12px;
  padding: 20px;
  border-radius: 12px;

  .pollResult {
    width: 100%;
    height: 45px;
    background-color: #fff;
    border-radius: 8px;
  }
}

.option {
  background: #ff5722;
  border-radius: 8px;
  height: 45px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: width 0.5s linear;
}

.selected {
  background: #ff5722;
  color: #fff;
}

.optionLabel {
  font-size: 16px;
}

.percentage {
  font-size: 14px;
  font-weight: bold;
}

.bottom {
  display: flex;
  margin-left: 20px;

  .retakePoll {
    margin-top: 20px;
    font-size: 14px;
    color: #ffffff55;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: -1px;
    bottom: -5px;
  }
}

@media screen and (max-width: 1180px) {
  .pollResultsCard {
    width: 80%;

    .option {
      padding: 0px 6px;
    }
  }
}
