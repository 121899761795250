.cardContainer {
  width: 90%;
  height: 105px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0px;

  .header {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 16px;
      font-family: "Montserrat-SemiBold";
    }
    div {
      &:first-child {
        display: flex;
        align-items: center;
      }
    }

    .orangeLabel {
      background-color: #ff6333;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .dateLabel {
      font-size: 10px;
      color: #cbcbcb;
    }
  }
  .descriptionLabel {
    width: 95%;
    font-size: 16px;
  }

  @media screen and (max-width: 1180px) {
    height: 105px;

    .descriptionLabel,
    .header {
      width: 90%;
    }
  }
}
