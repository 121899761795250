.wrapper {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    .backButtonContainer {
      display: flex;
      align-items: center;
    }
  }

  .step {
    width: 100px;
    height: 48px;
    border-radius: 12px;
    background-color: #ff6333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Montserrat-SemiBold";
    margin: 50px auto;
  }

  .labels {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    h3 {
      font-family: "Montserrat-SemiBold";
      font-size: 22px;
    }
    span {
      font-size: 16px;
      padding-top: 20px;
    }

    span:last-child {
      padding-top: 10px;
    }
  }

  button {
    margin: 100px auto;
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;

    button {
      margin: 50px auto;
    }
  }
}
