.container {
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 450px;

  h1 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  span {
    font-size: 16px;

    &:last-child {
      font-size: 20px;
      font-family: "Montserrat-SemiBold";
    }
  }
}
