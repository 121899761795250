@import "./colors";
@import "./fonts";
@import "./buttons";
@import url(/src/pages/Purchasing/ProductGrid.module.scss);

body {
  margin: 0;
  font-family: "Montserrat-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;

  @media screen and (max-width: 1180px) {
    overflow: hidden;
  }
}

html,
body {
  width: 100dvw;
}

html {
  height: -webkit-fill-available;
}

.primaryColor {
  color: $main-color;
}

h1 {
  font-size: 22px;
  font-family: "Montserrat-Bold";
}

.lt-300 {
  font-family: "Montserrat-Light";
}

.reg-400 {
  font-family: "Montserrat-Regular";
}

.med-500 {
  font-family: "Montserrat-Medium";
}

.bold-700 {
  font-family: "Montserrat-Bold";
}

.pointer {
  cursor: pointer;
}

.secondInput {
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-bottom: 0px;
  padding: 5px 0px;
  min-height: 30px;
  background-color: transparent;
  color: var(--color);
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  caret-color: #600f83;
  border-radius: 0px;
  border-left: 0px;
  border-bottom: 1px solid #20202035;
  outline: none;

  &::placeholder {
    color: var(--color);
  }
}

.secondSelectStyle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-bottom: 0px;
  padding: 10px 10px 10px 0px;
  min-height: 30px;
  background-color: transparent;
  color: var(--color);
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  caret-color: #600f83;
  border-radius: 0px;
  border-left: 0px;
  border-bottom: 1px solid #20202035;
  border-left: 0px !important;
  outline: none;

  &::placeholder {
    color: var(--color);
  }

  &:disabled {
    opacity: 1;
  }
}

.firstColour {
  color: $firstColour;

  &::placeholder {
    color: $firstColour;
  }
}

.secondColour {
  color: $secondColour;

  &::placeholder {
    color: $secondColour;
  }
}

.thirdColour {
  color: $thirdColour;

  &::placeholder {
    color: $thirdColour;
  }
}

// EVENT FILTER STYLES
.eventFilters {
  width: 35%;
  min-width: 450px;
  max-width: 450px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;

  .filter {
    width: 140px;
    height: 25px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 2px;
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    margin: 0 auto;
    min-width: 320px;
    .filter {
      word-break: keep-all;
    }
  }
}

// WALLET TABS ON HEADER

.walletTabs {
  margin: 20px auto 20px auto;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  display: none !important;
}

// MUI STYLES
.MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root.Mui-error {
  border-color: #e2e2e2 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #e2e2e2 !important;
  border-bottom-width: 2px !important;
}
.MuiOutlinedInput-input {
  padding: 0px 0px 10px 0px !important;
  font-size: 16px !important;
}

.Mui-disabled {
  -webkit-text-fill-color: unset !important;
  font-family: "Montserrat-Regular" !important;
}

.css-7anqxj-MuiFormControl-root {
  border-bottom: 1px solid rgba(32, 32, 32, 0.2078431373) !important;
}

.css-16q3byk-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-13dk7sd {
  visibility: hidden;
  height: 0px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  margin-top: 10px;

  @media screen and (max-width: 1180px) {
    margin-top: -30px;
    margin-left: 10px;
  }

  @media screen and (max-width: 900px) {
    margin-top: -40px;
    margin-left: 5px;
  }
}

.css-whxibi-MuiBadge-badge,
.css-raibn {
  background: #ff6333 !important ;
  color: #fff !important;
  text-align: center;
  font-family: "Montserrat-Bold";
  font-size: 12px;
  font-style: normal;
  line-height: 40px;
}

.css-1pw81iq-MuiInputBase-root-MuiFilledInput-root {
  background-color: transparent !important;
  font-family: "Montserrat-Bold";
}

.css-1ym9hb9 {
  background-color: transparent !important;
}

// MUI Styles END

.d-flex {
  display: flex;
}

.txtAlignCenter {
  text-align: center;
}

.m-auto {
  margin: 0 auto;
}

.d-none {
  display: none;
}

// Terms and conditions auth style

tbody tr td div div,
td span {
  text-align: justify !important;
  font-family: "Montserrat-Regular" !important;
  font-size: 16px !important;
  margin-bottom: 20px !important;
  line-height: 25px !important;
  text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25) !important;
}

.StripeElement {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 20px;
}

// Gamification boxes animated
.animatedBox {
  transform-origin: center;
  transform: scale(0.5) rotate(180deg);
  transition: 0.3s linear;
}

.activeAnimatedBox {
  transform: scale(1) !important;
}

// Slick slider

.slick-dots {
  top: 0px;
  bottom: unset !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #540a9d;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  margin-top: 10px;
}

.slick-slide div {
  margin: 0 auto;
}

option {
  color: #000 !important;
}

/* Container breakpoints */
.container {
  max-width: 330px;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

@media screen and (min-width: 640px) {
  .container {
    max-width: 610px;
  }
}

@media screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }
}

@media screen and (min-width: 1180px) {
  .container {
    max-width: 1460px;
  }
}

/* Container breakpoints END */

.ReactModal__Overlay {
  background-color: transparent !important;
  border: none !important;
  z-index: 1201;
  backdrop-filter: blur(50px);
}

// Toaster
.Toastify__toast-container--top-center {
  left: 10%;
  @media screen and (min-width: 1180px) {
    left: 15%;
    transform: unset;
  }
}

.Toastify__close-button--light {
  margin-top: 6px;

  @media screen and (max-width: 1180px) {
    margin-top: 15px;
  }
}

:root {
  --toastify-toast-min-height: 50px;
}

@media screen and (max-width: 1180px) {
  .intercom-launcher,
  .intercom-active-launcher,
  .intercom-launcher-frame {
    display: none !important;
    visibility: hidden;
  }
  .intercom-messenger-frame {
    z-index: 999999999999 !important;
  }

  .intercom-messenger-frame .closeMenuIcon {
    display: none !important;
  }

  .intercom-namespace .intercom-dfosxs {
    bottom: 48px !important;
    right: 32px !important;
  }

  .intercom-borderless {
    bottom: 20px !important;
  }
  .intercom-namespace .intercom-with-namespace-52k34s {
    display: none !important;
  }

  .Toastify__toast-container--top-center {
    z-index: 99999999999 !important;
  }
}

button,
div,
.intercom-1te6fkt {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

// Leaflet custom styles

.marker-cluster-medium div,
.marker-cluster-small div {
  background-color: #ff6333 !important;
  color: #fff !important;
}

.marker-cluster-small,
.marker-cluster-medium {
  background-color: #fac0ae70 !important;
  color: #fff !important;
}

.leaflet-container {
  outline: none !important;
}

.marker-cluster-large div {
  background-color: #ff6333 !important;
  color: #fff !important;
}

.leaflet-container svg {
  g {
    path {
      stroke: #ff6333 !important;
      fill: #ff6333 !important;
      stroke-width: 0.5px !important;
    }
  }
}

.leaflet-bottom {
  display: none !important;
}

.shownText {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  overflow: auto;

  span {
    font-family: "Montserrat-Regular" !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
    line-height: 25px !important;
    text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25) !important;
    text-align: justify !important;
    word-break: break-word;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pp1-card {
  .swiper-button-prev,
  .swiper-button-next {
    background: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 99999 !important;

    &::after {
      width: 100%;
      height: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
    }
  }
  .swiper-button-prev {
    left: unset !important;
    bottom: 29px !important;
    right: 160px !important;
    top: unset !important;

    &::after {
      margin-right: 5px;
    }
  }

  .swiper-button-next {
    top: unset !important;
    right: 50px !important;
    bottom: 29px;
  }

  .swiper-pagination {
    width: 27% !important;
    right: 0px !important;
    bottom: 35px !important;
    left: unset !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  .swiper-pagination-bullet {
    background-color: #626262;
  }

  @media screen and (max-width: 1180px) {
    .swiper-pagination {
      width: 100px !important;
    }

    .swiper-button-prev {
      left: calc(50% - 80px) !important;
    }

    .swiper-button-next {
      right: calc(50% - 80px) !important;
    }

    .swiper-pagination {
      right: calc(50% - 47px) !important;
    }
  }
}
