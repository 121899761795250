.wrapper {
  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    svg {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  .content {
    width: 80%;
    min-height: 550px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    border-radius: 12px;
    padding: 10px 20px;
    margin: 0px auto 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      color: var(--color);
      font-family: "Montserrat-SemiBold";
      font-size: 12px;
      text-transform: uppercase;
    }

    .lSearch {
      position: relative;
      width: 100%;
      height: 40px;

      svg {
        position: absolute;
        top: 12px;
        left: 5px;
      }

      input {
        width: calc(100% - 25px);
        height: 100%;
        border: none;
        border-radius: 8px;
        outline: none;
        background-color: #f8f8f8;
        transition: all 0.2s ease-in-out;
        color: #000;
        font-family: "Montserrat-SemiBold";
        font-size: 12px;
        padding-left: 25px;

        &::placeholder {
          color: #000;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;

      div {
        width: 160px;
        height: 48px;
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          background-color: #f8f8f8;
          margin-right: 10px;
        }

        &:last-child {
          background-color: var(--color);
          color: #fff;
        }
      }
    }
  }
}
