.wrapper {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  .header {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    top: 0;
    position: sticky;
    z-index: 999;
    padding-top: 10px;

    svg {
      width: 32px;
    }

    .backButtonContainer {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin: 50px 0px;
    }

    span {
      font-size: 14px;
    }

    .buttons {
      margin: 50px 0px;
      div {
        width: 250px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
        border-radius: 12px;
        border: 2px solid #000;
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        cursor: pointer;
      }

      button {
        min-width: 250px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
  }
}
