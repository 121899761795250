.wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .mapContainer {
    // height: calc(100dvh - 230px) !important;
    height: 100%;
    width: 100%;
  }

  .customMarker {
    width: 100px;
    min-height: 53px;
    background-color: #ff6333;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: "Montserrat-SemiBold";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 0.125rem 1.25rem #fac0ae !important;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 14px solid #f76334;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }
  }
  .label {
    width: 85%;
    font-size: 9px;
    font-family: "Montserrat-Regular";
    padding: 0px 5px;
  }
  .price {
    width: 85%;
    font-size: 12px;
    font-family: "Montserrat-SemiBold";
    padding: 0px 5px;
  }

  .showcasedProduct {
    max-width: 350px;
    width: 90%;
    height: 210px;
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    bottom: 100px;
    left: calc(50% - 230px);
    z-index: 999;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .leftPart,
    .rightPart {
      width: 50%;
    }

    .leftPart {
      display: flex;
      align-items: center;
      img {
        width: 85px;
        height: 165px;
        object-fit: contain;
        margin: 0 auto;
      }
    }
    .rightPart {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .topInfo {
        font-size: 9px;

        .productRating {
          display: flex;
          align-items: center;
          color: var(--color);
          margin-top: 5px;
          svg {
            margin-right: 5px;
          }
        }
      }
      .bottomInfo {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            color: var(--color);
            font-size: 9px;
          }

          &:nth-child(2) {
            font-size: 12px;
            font-family: "Montserrat-SemiBold";
            color: var(--color);
            margin: 5px 0px;
          }

          &:last-child {
            font-size: 14px;
            font-family: "Montserrat-SemiBold";
          }
        }
      }
    }

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
      font-family: "Montserrat-Bold";
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1180px) {
    .mapContainer {
      height: calc(100dvh - 160px) !important;
    }

    .showcasedProduct {
      max-width: unset;
      left: 50%;
      transform: translate(-50%, -10%);
    }
  }
}
